import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import "./about.css"
import photoAbout from "../../images/about.jpg"
import BookNowButton from "../../components/BookNowButton"

const About = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main className="page container">
        <PageHeader title="About" description="" />
        <img src={photoAbout} alt="photo-about" />
        <div className="about">
          
            <p>
              Immerse yourself in the captivating performances of Boris, a classically trained pianist with a distinguished record of winning numerous piano competitions. Whether you're hosting a wedding, birthday bash, or any special event, Boris infuses each occasion with his exceptional talent and expertise.
            </p>

            <p>
            With a repertoire that seamlessly transitions across classical, jazz, pop, and more, Boris tailors his music to perfectly suit the ambiance of your event. From intimate gatherings to lavish affairs, Boris's exquisite playing sets the stage for unforgettable memories.
            </p>


            <p>
            Based in Chicago, Boris is available to enchant audiences both locally and internationally. Elevate your event with the timeless elegance of live piano music by booking Boris today. Ensure your celebration resonates with the harmonious brilliance and award-winning skill that only Boris can provide.
            </p>
        </div>


      </main>
      <BookNowButton/>
      <Footer />
    </>
  );
};

export default About;
