import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import "./faq.css"

import BookNowButton from "../../components/BookNowButton"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './accordion.css';

const Faq = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main className="page container">
        <PageHeader title="FAQ" description="" />
        <div className="faq">
          <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I book Boris_Piano?
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <p>
                      Booking pianist Boris for your event is a straightforward process designed to ensure your satisfaction every step of the way:
                      </p>
                      <ul>
                        <li>
                          Get in touch: Reach out to Boris via the contact form on our website or contact him directly through email or phone.
                        </li>
                        <li>
                        Schedule a meeting: Once you've made contact, we'll schedule a meeting to discuss all the details of your event, including music preferences, venue logistics, and any special requests you may have.
                        </li>
                        <li>
                        Contract and deposit: After our meeting, we'll send you a contract outlining the agreed-upon terms. To secure your booking, a 50% deposit is required.
                        </li>
                        <li>
                        Customized playlist: Feel free to request any songs you'd like Boris to perform during your event. He's happy to accommodate your preferences and create a personalized musical experience.
                        </li>
                        <li>
                        Confirmation check-in: One week before your event, you'll receive a confirmation check-in message from Boris to ensure everything is on track and to address any last-minute details or changes.
                        </li>
                      </ul>

                      <p>
                        With Boris , booking exceptional live piano music for your event is easy and hassle-free. Contact us today to reserve your date and elevate your event with the enchanting sounds of Boris's music.
                      </p>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          Sound & Setup
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <p>
                        Boris is equipped and experienced in performing with his own professional-grade sound equipment suitable for small to medium-sized events. Unless the event is large, multifaceted and already includes a sound engineer, i.e. corporate events and weddings, the booking fee includes the provision of all equipment, sound and setup. If your event requires specific audiovisual equipment or has unique sound system requirements, it will be discussed during the booking process and he will work with you to accommodate those needs. 
                      </p>
                  </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        Booking nationwide and internationally?
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                      <p>
                      Boris is available for both nationwide and international travel for performances. He is experienced in traveling for musical engagements and is available for events across the country and around the world. Whether you are looking to book him for a local event or an event abroad, he is open to discussing travel arrangements and logistics to make your event a success, wherever it may be.
                      </p>
                  </AccordionItemPanel>
              </AccordionItem>
          </Accordion>
        </div>
     
      </main>
      <BookNowButton/>
      <Footer />
    </>
  );
};

export default Faq;
